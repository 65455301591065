import { default as Muislider } from "@mui/material/Slider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

function Slider(props) {
    const get_labels = (x) => {
        return props.marks[x - 1];
    };

    return (
        <FormControl fullWidth className="m-4" >
            <FormLabel className="m-2" sx={{ fontWeight: "bold" }}>{props.label}</FormLabel>
            <Muislider
                aria-label="Always visible"
                defaultValue={props.default}
                valueLabelDisplay="on"
                step={props.step}
                marks
                min={props.min}
                max={props.max}
                valueLabelFormat={get_labels}
                onChange={(e) => props.on_change(e, props.pat_index)}
            />
        </FormControl>
    );
}

export default Slider;
