// import logo from './logo.svg';
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import Slider from "./components/Slider";
import "bootstrap/dist/css/bootstrap.css";
import Radio from "./components/Radio";

const thickness = [6, 9, 12, 16, 17, 19, 22, 25, 31, 38, 45, 50];
const bp_thickness = [6, 8, 10, 12, 16, 20, 25, 26];
const bp_material = ["Steel", "Stainless Steel", "Quench Tempered"];
const defaults = [3, 3, 1, 9, 1, 2, "S", "", 0, 0, 0, 0, 0, 0];

function App() {
    const [pat, set_pat] = useState([...defaults]);

    const handle_change_single = (e, pat_index) => {
        const pat_copy = pat;
        pat_copy[pat_index] = e.target.value;
        set_pat([...pat_copy]);
    };

    const handle_change_double = (e, pat_index) => {
        const pat_copy = pat;
        let pat_indexes = pat_index.toString().split("");
        let values;
        if (pat_index === 23) {
            values = thickness[e.target.value - 1]
                .toString()
                .padStart(2, "0")
                .split("");
        } else if (pat_index === 45) {
            values = bp_thickness[e.target.value - 1]
                .toString()
                .padStart(2, "0")
                .split("");
        }
        pat_copy[pat_indexes[0]] = values[0];
        pat_copy[pat_indexes[1]] = values[1];
        set_pat([...pat_copy]);
    };

    const handle_change_radio = (e, pat_index) => {
        const pat_copy = pat;
        let pat_indexes = pat_index.toString().split("");
        pat_copy[pat_indexes[0]] = e.target.value[0];
        pat_copy[pat_indexes[1]] = e.target.value[1];
        set_pat([...pat_copy]);
    };

    useEffect(() => {});

    return (
        <React.Fragment>
            <CssBaseline />
            <div className="container d-flex flex-column align-items-center">
                <Button variant="contained">Pat Number: {pat}</Button>
                <Slider
                    pat_index={0}
                    min={1}
                    max={5}
                    step={1}
                    marks={[
                        "Medium Wear Abrasion",
                        "Medium Wear Abrasion",
                        "High Wear Abrasion",
                        "Imporved Material Flow",
                        "High Wear Abrasion",
                    ]}
                    label="Abrasion Range"
                    on_change={handle_change_single}
                    default={defaults[0]}
                />

                <Slider
                    pat_index={1}
                    default={defaults[1]}
                    min={1}
                    max={5}
                    step={1}
                    marks={[
                        "Low Impact",
                        "Medium Impact",
                        "Medium Impact",
                        "Low Impact",
                        "Low Impact",
                    ]}
                    label="Impact Scale"
                    on_change={handle_change_single}
                />

                <Slider
                    pat_index={23}
                    default={
                        thickness.findIndex(
                            (e) =>
                                e.toString().padStart(2, "0") ===
                                String(defaults[2]) + String(defaults[3])
                        ) + 1
                    }
                    min={1}
                    max={thickness.length}
                    step={1}
                    marks={thickness.map(
                        (e) => String(e).padStart(2, "0") + "mm"
                    )}
                    label="Thickness"
                    on_change={handle_change_double}
                />

                <Slider
                    pat_index={45}
                    default={
                        bp_thickness.findIndex(
                            (e) =>
                                e.toString().padStart(2, "0") ===
                                String(defaults[4]) + String(defaults[5])
                        ) + 1
                    }
                    min={1}
                    max={bp_thickness.length}
                    step={1}
                    marks={bp_thickness.map(
                        (e) => String(e).padStart(2, "0") + "mm"
                    )}
                    label="Backing Plate Thickness"
                    on_change={handle_change_double}
                />
                <Radio
                    on_change={handle_change_radio}
                    pat_index={67}
                    values={bp_material}
                    default={defaults[6]}
                    label="Backing Plate Material Type"
                />
            </div>
        </React.Fragment>
    );
}

export default App;
