import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { default as Muiradio } from "@mui/material/Radio";

const to_initials = (e) => {
    const splitted = e.split(" ");
    return splitted.map((e) => e[0]).join("");
};

function Radio(props) {
    return (
        <FormControl fullWidth className="m-4">
            <FormLabel className="m-2" sx={{ fontWeight: "bold" }}>
                {props.label}
            </FormLabel>
            <RadioGroup defaultValue={props.default} row onChange={(e) => props.on_change(e, props.pat_index)}>
                {props.values.map((e) => (
                    <FormControlLabel
                        key={e}
                        value={to_initials(e)}
                        control={<Muiradio />}
                        label={e}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default Radio;
